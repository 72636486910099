import React, { useState } from 'react';

function PaymentForm() {
    const [ref, setRef] = useState('');

    const handleOnClick = (e) => {
        if (!ref) {
            e.preventDefault();
            alert('Please enter your reference');
        }
    };

    const paymentLink = `http://app.cleanerplanner.com/pay?Method=GoCardless&OrganisationId=OR00007C46NPV8&Reference=${encodeURIComponent(ref)}`;

    return (
        <div>
            <input
                id="cpgcref"
                type="text"
                placeholder="Enter first line of address"
                style={{
                    width: "100%",
                    fontSize: "16px",
                    textAlign: "center",
                    height: "40px",
                    padding: "0",
                    border: "1px solid #CCC",
                    borderRadius: "5px"
                }}
                value={ref}
                onChange={e => setRef(e.target.value)}
            />
            <a
                href={paymentLink}
                target="_blank"
                onClick={handleOnClick}
                rel="noreferrer" // for security reasons
            >
                <img
                    src="https://s3-eu-west-1.amazonaws.com/gocardless/images/public/buttons/updated/pay-with-gc-large.png"
                    alt="GoCardless payment button"
                    width="242"
                    height="43"
                    style={{ width: "242px", display: "block", margin: "10px auto", textAlign: "center" }}
                />
            </a>
        </div>
    );
}

export default PaymentForm;