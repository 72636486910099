import React, { useState, useEffect } from "react";
import { Container, Row } from 'react-bootstrap'
import "yet-another-react-lightbox/styles.css";

import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";

const importAll = (r) => r.keys().map(r);
const ImageFiles = importAll(require.context('../assets/gallery', false, /\.(png|jpe?g)$/));
const ReverseSortImages = ImageFiles.reverse();
const initialImages = ReverseSortImages.map(image => ({ src: image }));

const MasonryLightbox = () => {
    const [index, setIndex] = useState(-1);
    const [images, setImages] = useState(initialImages);

    useEffect(() => {
        fetch(process.env.PUBLIC_URL + '/gallery.json')
            .then((response) => response.json())
            .then((data) => {
                const updatedImages = images.map((image, i) => ({
                    ...image,
                    width: data[i].width,
                    height: data[i].height
                }));

                setImages(updatedImages);
            })
            .catch((error) => console.error(error));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (

        <Container>
            <Row className="d-flex align-items-start py-3 py-xs-5">
                <PhotoAlbum
                    layout="rows"
                    photos={images}
                    targetRowHeight={240}
                    onClick={({ index }) => setIndex(index)}
                />

                <Lightbox
                    styles={{ container: { backgroundColor: "rgba(0, 0, 0, .8)" } }}
                    open={index >= 0}
                    index={index}
                    close={() => setIndex(-1)}
                    slides={images}
                />
            </Row>
        </Container>

    );
}

export default MasonryLightbox;