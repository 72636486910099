import Header from '../components/Header'
import Footer from '../components/Footer'

import Hero from '../components/Section_Hero';
import Statement from '../components/Section_Statement'
import Section2Col from '../components/Section_2Col'
import TitleParagraphBtn from '../components/Section_TitleParagraphBtn'
import CheckList from '../components/Section_CheckList'
import Features from '../components/Section_Features'
import VideoSection from '../components/Section_Video'
import SlickSlider from '../components/Section_SlickSlider'
import FAQSection from '../components/Section_FAQ'

import { HOME } from '../content/en'

const WhatWeDo = () => {
    return <TitleParagraphBtn
        title={HOME.about.title}
        paragraph={HOME.about.paragraph}
        buttons={HOME.about.buttons}
    />
}

const AboutCheckList = () => <CheckList icon={HOME.checklist.icon} items={HOME.checklist.data} />


function Home() {
    return (
        <>

            <Header />

            <Hero
                backgroundImage={HOME.hero.background}
                title={HOME.hero.title}
                subtitle={HOME.hero.subtitle}
                buttons={HOME.hero.buttons}
            />

            <Statement content={HOME.statement} />

            <Section2Col
                colOne={WhatWeDo()}
                colTwo={AboutCheckList()}
            />

            <Section2Col
                colOne={<TitleParagraphBtn title="Why us..." paragraph={HOME.mission} />}
                colTwo={<VideoSection
                    src="/videos/gutter-cleaning.mp4"
                    seo={{
                        "title": "Gutter Cleaning Reel",
                        "desc": "Gutter cleaning services, before and after.",
                        "thumb": "/videos/gutter-clear-video-thumb.png"
                    }}
                />}
                classNames="flex-wrap-reverse"
            />

            <Features title={HOME.features.title} cards={HOME.features.cards} />

            <SlickSlider
                title={HOME.slider.title}
                background={HOME.slider.background}
                settings={HOME.slider.settings}
                reviews={HOME.slider.reviews}
            />
            <div className='divider'></div>
            <FAQSection title={HOME.faq.title} cols={HOME.faq.cols} faqs={HOME.faq.data} />
            <Footer />
        </>
    );
}

export default Home;
