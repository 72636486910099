import React, { useState } from 'react';
import { Container, Row, Form, Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phoneNumber: '',
        enquiryType: '',
        message: '',
    });

    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState('');

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // replace with your API endpoint and method
            const response = await fetch('https://s8tts9clqf.execute-api.eu-west-1.amazonaws.com/dev/contact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            // handle response here
            if (response.ok) {
                setModalContent('Your message was sent successfully!');
            } else {
                setModalContent('There was an error sending your message. Please try again.');
            }
        } catch (error) {
            setModalContent('There was an error sending your message. Please try again.');
        } finally {
            setShowModal(true);
        }
    };

    return (
        <Container>
            <Row>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                        <Form.Control
                            className="p-3 rounded-2 border-0 fst-italic fw-bold mb-3"
                            placeholder='Name*' name="name" onChange={handleInputChange}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Control
                            className="p-3 rounded-2 border-0 fst-italic fw-bold mb-3"
                            placeholder='Email*' name="email" type="email" onChange={handleInputChange}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Control
                            className="p-3 rounded-2 border-0 fst-italic fw-bold mb-3"
                            placeholder='Phone Number' name="phoneNumber" onChange={handleInputChange}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Select name="enquiryType" className="form-select p-3 rounded-2 border-0 fst-italic fw-bold mb-3 custom-select" onChange={handleInputChange}>
                            <option value="">Select enquiry type</option>
                            <option value="Window Cleaning Service">Window Cleaning Service</option>
                            <option value="Gutter Cleaning Service">Gutter Cleaning Service</option>
                            <option value="Payment">Payment</option>
                            <option value="Other">Other</option>
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Control
                            className="p-3 rounded-2 border-0 fst-italic fw-bold mb-3"
                            placeholder='Message' name="message" as="textarea" onChange={handleInputChange}
                        />
                    </Form.Group>

                    <Button variant="primary" className='btn-icon btn btn-primary btn-xl' type="submit">
                        <FontAwesomeIcon icon={faAngleDoubleRight} className="me-2" />
                        Send
                    </Button>
                </Form>
                <Modal show={showModal} onHide={() => setShowModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Contact Form</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{modalContent}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowModal(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Row>
        </Container>

    );
};

export default ContactForm;
