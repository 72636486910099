
const NotFound = () => {

    return (
        <div className="not-found">
            <h2>Sorry</h2>
            <p>Page not found</p>
        </div>

    )
}

export default NotFound;