import Strip from './Header_Strip';
import Navigation from './Header_Navigation';

import { GLOBAL } from '../content/en';

const Header = () => {

    return (
        <header>
            <Strip phoneNumber={GLOBAL.phone_number} email={GLOBAL.email} />
            <Navigation logo={GLOBAL.logo} />
        </header>
    )
}

export default Header;