import { Container, Col, Nav, NavItem } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import ROUTES from '../routes/routes';
import { GLOBAL } from '../content/en';

const Footer = () => {

    const currentYear = new Date().getFullYear();

    return (
        <Container>
            <footer className="row row-cols-5 py-5 my-5 border-top">
                <Col xs={12} sm={3} md={6}>
                    <Link to={ROUTES.HOME.path} className="d-flex align-items-center mb-3 link-dark text-decoration-none">
                        <img src={GLOBAL.logo} alt="Logo" className="logo p-3 w-100" />
                    </Link>
                    <p className="text-muted">© {currentYear}</p>
                </Col>

                <Col xs={4} sm={3} md={2}>
                    <h5>Services</h5>
                    <Nav className="flex-column">
                        <NavItem className="mb-2"><Link to={ROUTES.HOME.path} className="nav-link p-0 text-muted">Home</Link></NavItem>
                        <NavItem className="mb-2"><Link to={ROUTES.SERVICE_WC.path} className="nav-link p-0 text-muted">Window Cleaning</Link></NavItem>
                        <NavItem className="mb-2"><Link to={ROUTES.SERVICE_GT.path} className="nav-link p-0 text-muted">Gutter Cleaning</Link></NavItem>
                        <NavItem className="mb-2"><Link to={ROUTES.SERVICE_CM.path} className="nav-link p-0 text-muted">Commercial Cleaning</Link></NavItem>
                    </Nav>
                </Col>

                <Col xs={4} sm={3} md={2}>
                    <h5>Locations</h5>
                    <Nav className="flex-column">
                        <NavItem className="mb-2"><Link to={ROUTES.AREAS.path} className="nav-link p-0 text-muted">Area</Link></NavItem>
                        <NavItem className="mb-2"><Link to={ROUTES.AREA_CHR.path} className="nav-link p-0 text-muted">Chester</Link></NavItem>
                        <NavItem className="mb-2"><Link to={ROUTES.AREA_WXM.path} className="nav-link p-0 text-muted">Wrexham</Link></NavItem>
                        <NavItem className="mb-2"><Link to={ROUTES.AREA_OST.path} className="nav-link p-0 text-muted">Oswestry</Link></NavItem>
                        <NavItem className="mb-2"><Link to={ROUTES.AREA_SHR.path} className="nav-link p-0 text-muted">Shrewsbury</Link></NavItem>
                    </Nav>
                </Col>

                <Col xs={4} sm={3} md={2}>
                    <h5>Requests</h5>
                    <Nav className="flex-column">
                        <NavItem className="mb-2"><Link to={ROUTES.QUOTE.path} className="nav-link p-0 text-muted">New Quote</Link></NavItem>
                        <NavItem className="mb-2"><Link to={ROUTES.GALLERY.path} className="nav-link p-0 text-muted">Gallery</Link></NavItem>
                        <NavItem className="mb-2"><Link to={ROUTES.PAYMENT.path} className="nav-link p-0 text-muted">Payments</Link></NavItem>
                        <NavItem className="mb-2"><Link to={ROUTES.CONTACT_US.path} className="nav-link p-0 text-muted">Contact Us</Link></NavItem>
                    </Nav>
                </Col>
            </footer>
        </Container>
    )
}

export default Footer