import { Col, Button } from 'react-bootstrap';

const View_Questions = ({ currentSection, handleNext, handlePrev, formData }) => {

    const options = currentSection.options[0]

    return (
        <Col as="section" xs={12} className="bg-niklas-gradient rounded-5 p-2 p-md-5">
            <h2 className="fs-1 fw-bold text-center py-3 py-sm-5">{currentSection.title}</h2>
            <div className='d-grid gap-2 d-md-flex flex-wrap justify-content-center'>
                {Object.entries(options).map(([key, value]) => (
                    <Button
                        key={key}
                        size="sm"
                        variant="light"
                        value={key}
                        className={`m-2 fs-2 fw-bold fst-italic text-center rounded-pill btn-niklas ${formData[currentSection.field] === key ? "active" : ""}`}
                        onClick={() => handleNext(currentSection.field, key)}
                    >
                        <span className="gradient-text px-4">{value}</span>
                    </Button>
                ))

                }
            </div>
            <div className='d-flex flex-wrap justify-content-center'>
                {currentSection.field !== "serviceType" &&
                    <Button
                        variant="light"
                        size="sm"
                        className="fw-bold rounded-pill p-3 mt-5 bg-special-gradient"
                        onClick={handlePrev}
                    >Back</Button>
                }
            </div>
        </Col >
    )
}

export default View_Questions;