import Header from '../components/Header'
import Footer from '../components/Footer'

import Hero from '../components/Section_Hero';
import Section2Col from '../components/Section_2Col'
import TitleParagraphBtn from '../components/Section_TitleParagraphBtn'
import SlickSlider from '../components/Section_SlickSlider'
import FAQSection from '../components/Section_FAQ'

import { HOME, AREA_SHR } from '../content/en'

const AreaShrewsbury = () => {
    return (
        <>
            <Header />

            <Hero
                backgroundImage={AREA_SHR.hero.background}
                title={AREA_SHR.hero.title}
                subtitle={AREA_SHR.hero.subtitle}
            />

            <Section2Col
                colOne={
                    <TitleParagraphBtn
                        title={AREA_SHR.about.title}
                        paragraph={AREA_SHR.about.paragraph}
                        buttons={AREA_SHR.about.buttons}

                    />
                }
                colTwo={<img src={AREA_SHR.about.map} className='w-100 rounded-2' alt='Shrewsbury Location Map' />}
            />
            <SlickSlider
                title={HOME.slider.title}
                background={HOME.slider.background}
                settings={HOME.slider.settings}
                reviews={HOME.slider.reviews}
            />
            <div className='divider'></div>
            <FAQSection title={HOME.faq.title} cols={HOME.faq.cols} faqs={HOME.faq.data} />
            <Footer />
        </>
    );
}

export default AreaShrewsbury;
