import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function Statement({ content }) {
    return (
        <Container>
            <Row className='d-flex align-items-center'>
                <Col className="m-2">
                    <p className="h4 fst-italic text-center text-niklas py-3">
                        {content}
                    </p>
                </Col>
            </Row>
        </Container>
    );
}

export default Statement;