import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';

const Section2Col = ({ colOne, colTwo, classNames }) => {
    return (
        <div className="bg-light">
            <Container>
                <Row className={`d-flex align-items-center ${classNames}`}>
                    <Col xs={12} sm={6} className="p-3 p-md-5">
                        {colOne}
                    </Col>
                    <Col xs={12} sm={6} className="p-3 p-md-5">
                        {colTwo}
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Section2Col;