const routeConfig = {
    HOME: '/',
    SERVICE_WC: '/window-cleaning',
    SERVICE_GT: '/gutter-cleaning',
    SERVICE_CM: '/commercial-window-cleaning',
    AREAS: '/areas-covered',
    AREA_CHR: '/chester-window-cleaning',
    AREA_WXM: '/wrexham-window-cleaning',
    AREA_OST: '/oswestry-window-cleaning',
    AREA_SHR: '/shrewsbury-window-cleaning',
    GALLERY: '/our-portfolio',
    QUOTE: '/get-your-booking-quote',
    PAYMENT: '/make-a-payment',
    CONTACT_US: '/contact-us',
    PRIVACY_POLICY: '/privacy-policy',
    NOT_FOUND: '*'
};

module.exports = routeConfig