import Header from '../components/Header'
import Footer from '../components/Footer'

import Hero from '../components/Section_Hero';
import Statement from '../components/Section_Statement'
import Section2Col from '../components/Section_2Col'
import TitleParagraphBtn from '../components/Section_TitleParagraphBtn'
import CheckList from '../components/Section_CheckList'
import ContactForm from '../components/ContactForm'
import Features from '../components/Section_Features'
import SlickSlider from '../components/Section_SlickSlider'
import FAQSection from '../components/Section_FAQ'

import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'

import { GLOBAL, HOME, CONTACT_US } from '../content/en'

function Contact() {
    return (
        <>
            <Header />

            <Hero
                backgroundImage={CONTACT_US.hero.background}
                title={CONTACT_US.hero.title}
                subtitle={CONTACT_US.hero.subtitle}
                buttons={CONTACT_US.hero.buttons}
            />

            <Statement content={CONTACT_US.statement} />

            <Section2Col
                colOne={
                    <>
                        <TitleParagraphBtn
                            title={CONTACT_US.about.title}
                            paragraph={CONTACT_US.about.paragraph}
                        />
                        <CheckList items={[GLOBAL.phone_number, GLOBAL.email]} icon={[faPhone, faEnvelope]} />
                    </>
                }
                colTwo={<ContactForm />}
            />
            <Features title={HOME.features.title} cards={HOME.features.cards} />
            <SlickSlider
                title={HOME.slider.title}
                background={HOME.slider.background}
                settings={HOME.slider.settings}
                reviews={HOME.slider.reviews}
            />
            <div className='divider'></div>
            <FAQSection title={HOME.faq.title} cols={HOME.faq.cols} faqs={HOME.faq.data} />
            <Footer />
        </>
    );
}

export default Contact;