import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap/';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';

import ROUTES from '../routes/routes';

const Strip = ({ phoneNumber, email }) => {
    return (
        <div className="header-strip bg-primary">
            <Container>
                <Row className="py-2">
                    <Col className='d-flex justify-content-between align-items-center'>
                        <div className="me-4">
                            <FontAwesomeIcon icon={faPhone} className='text-white pe-2' />
                            <Link to={"tel:" + phoneNumber} className="fs-6 text-white text-decoration-none align-middle">{phoneNumber}</Link>
                        </div>
                        <div className="me-4 d-none d-sm-block">
                            <FontAwesomeIcon icon={faEnvelope} className='text-white pe-2' />
                            <Link to={"mailto:" + email} className="fs-6 text-white text-decoration-none align-middle">{email}</Link>
                        </div>
                        <div className="ms-auto">
                            <Link to={ROUTES.QUOTE.path} className="btn btn-light text-niklas fw-bold mx-0">Get Quote</Link>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Strip;