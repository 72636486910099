import React from 'react';
import { Helmet } from "react-helmet";
import { chunk } from 'lodash';
import { Container, Accordion, Row, Col } from 'react-bootstrap';

function FAQSchema({ faqData }) {
    const schema = {
        "@context": "http://schema.org/",
        "@type": "FAQPage",
        "mainEntity": faqData.map(({ question, answer }) => ({
            "@type": "Question",
            "name": question,
            "acceptedAnswer": {
                "@type": "Answer",
                "text": answer
            }
        }))
    };

    return (
        <Helmet>
            <script type='application/ld+json'>
                {JSON.stringify(schema)}
            </script>
        </Helmet>
    );
}

const FAQSection = ({ title, cols = 1, faqs }) => {

    const getColWidth = 12 / cols;
    const faqChunks = chunk(faqs, Math.ceil(faqs.length / cols));

    return (
        <>
            <FAQSchema faqData={faqs} />
            <Container>
                <Row className="py-5 d-flex align-items-start">
                    <Col md={12}>
                        <h2 className="display-4 text-niklas text-center">{title}</h2>
                    </Col>
                    {faqChunks.map((faqChunk, i) => (
                        <Col md={getColWidth} key={i}>
                            <Accordion defaultActiveKey={String(i)} flush>
                                {faqChunk.map((faq, j) => (
                                    <Accordion.Item eventKey={i + " " + j} key={j}>
                                        <Accordion.Header>{faq.question}</Accordion.Header>
                                        <Accordion.Body>{faq.answer}</Accordion.Body>
                                    </Accordion.Item>
                                ))}
                            </Accordion>
                        </Col>
                    ))}
                </Row>
            </Container>
        </>
    );
}

export default FAQSection