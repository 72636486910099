import { Container, Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ROUTES from "../routes/routes";
import DOMPurify from 'dompurify';

const TitleParagraphBtn = ({ title, paragraph, buttons }) => {

    const getURL = (urlType) => {
        console.log(ROUTES[urlType].path);
        return ROUTES[urlType].path;
    }

    return (
        <Container>
            <Row>
                <Col className="m-2">
                    <h2 className="display-4 text-niklas mb-4">{title}</h2>
                    {Array.isArray(paragraph)
                        ? paragraph.map((p, i) => <p key={i} className="mb-4 lh-lg" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(p) }} />)
                        : <p className="mb-4 lh-lg" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(paragraph) }} />
                    }
                    {buttons && buttons.map((button, index) => (
                        <Button
                            key={index}
                            variant={button.variant || "primary"}
                            size={button.size || "lg"}
                            className={button.className || "btn-icon"}
                            href={getURL(button.href) || "#"}
                        >
                            <FontAwesomeIcon icon={button.icon} className="fs-5 me-2" />
                            {button.text}
                        </Button>
                    ))}
                </Col>
            </Row>
        </Container>
    )
}

export default TitleParagraphBtn;