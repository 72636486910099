import { Container, Row, Col, Card } from 'react-bootstrap';

function Features({ title, cards }) {
    return (
        <Container className='my-5'>
            <Row className="py-3 py-xs-5 d-flex align-items-center">
                <Col xs={12}>
                    <h2 className="display-4 text-niklas text-center mb-5">{title}</h2>
                </Col>
                {cards.map((card, index) => (
                    <Col xs={12} md={6} key={index}>
                        <Card key={index} className="border-0">
                            <Card.Body className="d-flex align-items-start">
                                <img src={card.icon} alt="icon" className="feature-icons" />
                                <div>
                                    <Card.Title as="h3" className='display-6 text-niklas'>{card.title}</Card.Title>
                                    <Card.Text as="p">{card.description}</Card.Text>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    );
}

export default Features;