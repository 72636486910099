import { Helmet } from "react-helmet";
import { GLOBAL } from '../content/en';

const globalSchemaOrg = () => {
    return {
        "@context": "http://schema.org",
        "@type": "ProfessionalService",
        "name": GLOBAL.name,
        "logo": GLOBAL.logo,
        "url": GLOBAL.schema.url,
        "email": GLOBAL.email,
        "telephone": GLOBAL.phone_number,
        "priceRange": GLOBAL.schema.priceRange,
        "address": [
            {
                "@type": "PostalAddress",
                "streetAddress": GLOBAL.addresses.wrexham.street,
                "addressLocality": GLOBAL.addresses.wrexham.city,
                "postalCode": GLOBAL.addresses.wrexham.postcode
            },
            {
                "@type": "PostalAddress",
                "streetAddress": GLOBAL.addresses.chester.street,
                "addressLocality": GLOBAL.addresses.chester.city,
                "postalCode": GLOBAL.addresses.chester.postcode
            }
        ],
        "sameAs": [GLOBAL.schema.facebook]
    };
}

const videoSchemaOrg = (title, desc, videoThumb, videoPath) => {
    return {
        "@context": "https://schema.org",
        "@type": "VideoObject",
        "name": title,
        "description": desc,
        "thumbnailUrl": [
            `${GLOBAL.schema.url + videoThumb}`
        ],
        "contentUrl": GLOBAL.schema.url + videoPath,
        "publisher": {
            "@type": "Organization",
            "name": GLOBAL.name,
            "logo": {
                "@type": "ImageObject",
                "url": GLOBAL.logo
            }
        },
        "contentRating": {
            "@type": "Rating",
            "ratingValue": "E",
            "ratingExplanation": "Suitable for all audiences."
        }
    }
}

export const VideoSEO = ({ title, desc, videoThumb, videoPath, logo }) => {
    return (
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(videoSchemaOrg(title, desc, videoThumb, videoPath, logo))}</script>
        </Helmet>
    )
}

const SEO = ({ title, description, path, children }) => {
    return (
        <>
            <Helmet>
                <title>{title}</title>
                <script type="application/ld+json">{JSON.stringify(globalSchemaOrg)}</script>
                <link rel="canonical" href={path} />
                <meta name="description" content={description} />
                <meta property="og:type" content="business.business" />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:image" content={GLOBAL.logo} />
                <meta property="og:url" content={GLOBAL.schema.url + path} />
                <meta property="og:email" content={GLOBAL.email} />
                <meta property="og:phone_number" content={GLOBAL.phone_number} />

            </Helmet>
            {children}
        </>
    )
}

export default SEO