import Home from '../pages/Home';
import NotFound from '../pages/NotFound';

import WindowCleaning from '../pages/WindowCleaning';
import GutterCleaning from '../pages/GutterCleaning';
import CommercialCleaning from '../pages/CommercialCleaning'

import Areas from '../pages/Areas'
import AreaChester from '../pages/AreaChester';
import AreaWrexham from '../pages/AreaWrexham';
import AreaOswestry from '../pages/AreaOswestry';
import AreaShrewsbury from '../pages/AreaShrewsbury'

import Gallery from '../pages/Gallery'
import GetYourQuote from '../pages/GetYourQuote'
import Payments from '../pages/Payments'
import Contact from "../pages/Contact";
import PrivacyPolicy from "../pages/PrivacyPolicy";

import ROUTE_CONFIG from './routeConfig';

const ROUTES = {
    HOME: { path: ROUTE_CONFIG.HOME, element: <Home />, exact: true },
    SERVICE_WC: { path: ROUTE_CONFIG.SERVICE_WC, element: <WindowCleaning /> },
    SERVICE_GT: { path: ROUTE_CONFIG.SERVICE_GT, element: <GutterCleaning /> },
    SERVICE_CM: { path: ROUTE_CONFIG.SERVICE_CM, element: <CommercialCleaning /> },
    AREAS: { path: ROUTE_CONFIG.AREAS, element: <Areas /> },
    AREA_CHR: { path: ROUTE_CONFIG.AREA_CHR, element: <AreaChester /> },
    AREA_WXM: { path: ROUTE_CONFIG.AREA_WXM, element: <AreaWrexham /> },
    AREA_OST: { path: ROUTE_CONFIG.AREA_OST, element: <AreaOswestry /> },
    AREA_SHR: { path: ROUTE_CONFIG.AREA_SHR, element: <AreaShrewsbury /> },
    GALLERY: { path: ROUTE_CONFIG.GALLERY, element: <Gallery /> },
    QUOTE: { path: ROUTE_CONFIG.QUOTE, element: <GetYourQuote /> },
    PAYMENT: { path: ROUTE_CONFIG.PAYMENT, element: <Payments /> },
    CONTACT_US: { path: ROUTE_CONFIG.CONTACT_US, element: <Contact /> },
    PRIVACY_POLICY: { path: ROUTE_CONFIG.PRIVACY_POLICY, element: <PrivacyPolicy /> },
    NOT_FOUND: { path: ROUTE_CONFIG.NOT_FOUND, element: <NotFound /> }
};


export default ROUTES