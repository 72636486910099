import Header from '../components/Header'
import Footer from '../components/Footer'

import Hero from '../components/Section_Hero';

import { Container, Row, Col } from 'react-bootstrap'

import { PRIVACY_POLICY } from '../content/en'

const PrivacyPolicy = () => {
    return (
        <>

            <Header />

            <Hero
                backgroundImage={PRIVACY_POLICY.hero.background}
                title={PRIVACY_POLICY.hero.title}
                subtitle={PRIVACY_POLICY.hero.subtitle}
            />

            <Container>
                <Row className="py-5 d-flex align-items-start">
                    <Col xs={12}>
                        <h2 className="display-4 text-niklas mb-4">{PRIVACY_POLICY.hero.title}</h2>
                    </Col>
                    <Col xs={12}>
                        {PRIVACY_POLICY.content.map((section, index) => (
                            <div key={index} className="privacyPolicy__section">
                                <h3>{section.title}</h3>
                                <p className="mb-4 lh-lg">{section.paragraph}</p>
                            </div>
                        ))}
                    </Col>
                </Row>
            </Container>

            <Footer />
        </>
    );
}

export default PrivacyPolicy;
