import React from 'react';
import ROUTES from '../routes/routes'
import { Button, Col, Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Hero = ({ backgroundImage, title, subtitle, buttons }) => {

    const getURL = (urlType) => {
        console.log(ROUTES[urlType].path);
        return ROUTES[urlType].path;
    }

    return (
        <div className="hero py-5" style={{ backgroundImage: `url(${backgroundImage})` }}>
            <Container>
                <Row className='content'>
                    <Col xs={12}>
                        <h1 className="display-5 fw-bold text-shadow">{title}</h1>
                        {subtitle && <p className="lead mb-4">{subtitle}</p>}
                    </Col>
                    <Col xs={12} className="mx-auto">
                        <div className="d-grid gap-2 d-md-flex justify-content-evenly">
                            {buttons && buttons.map((button, index) => (
                                <Button
                                    key={index}
                                    variant={button.variant || "primary"}
                                    size={button.size || "xl"}
                                    className={button.className || "btn-icon"}
                                    href={getURL(button.href) || "#"}
                                >
                                    <FontAwesomeIcon icon={button.icon} className="me-2" />
                                    {button.text}
                                </Button>
                            ))}
                        </div>
                    </Col>
                </Row>
            </Container>
        </div >
    );
}

export default Hero;