import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Navbar, Nav, NavDropdown } from 'react-bootstrap';

import ROUTES from '../routes/routes';

const Navigation = ({ logo }) => {

    return (
        <div className="px-3 py-2">
            <Container>
                <Navbar expand="lg" className="d-flex align-items-center justify-content-between">
                    <Link to={ROUTES.HOME.path} className="col">
                        <img src={logo} alt="Logo" className="logo" />
                    </Link>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav" className="text-center">
                        <Nav className="w-100 col-12 col-lg-9 d-flex justify-content-around">
                            <NavDropdown title="Services" className="text-niklas my-2">
                                <Link to={ROUTES.SERVICE_WC.path} className="nav-link py-2 text-center text-niklas">Windows Cleaning</Link>
                                <Link to={ROUTES.SERVICE_GT.path} className="nav-link py-2 text-center text-niklas">Gutter Cleaning</Link>
                                <Link to={ROUTES.SERVICE_CM.path} className="nav-link py-2 text-center text-niklas">Commercial</Link>
                            </NavDropdown>
                            <NavDropdown title="Locations" className="text-niklas my-2">
                                <Link to={ROUTES.AREA_CHR.path} className="nav-link py-2 text-center text-niklas">Chester</Link>
                                <Link to={ROUTES.AREA_WXM.path} className="nav-link py-2 text-center text-niklas">Wrexham</Link>
                                <Link to={ROUTES.AREA_OST.path} className="nav-link py-2 text-center text-niklas">Oswestry</Link>
                                <Link to={ROUTES.AREA_SHR.path} className="nav-link py-2 text-center text-niklas">Shrewsbury</Link>
                            </NavDropdown>
                            <Link to={ROUTES.QUOTE.path} className="nav-link text-niklas my-2 py-2">Get Quote</Link>
                            <Link to={ROUTES.GALLERY.path} className="nav-link text-niklas my-2 py-2">Gallery</Link>
                            <Link to={ROUTES.PAYMENT.path} className="nav-link text-niklas my-2">Payment</Link>
                            <Link to={ROUTES.CONTACT_US.path} className="nav-link text-niklas my-2">Contact Us</Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </Container >
        </div >
    );
}

export default Navigation;