import { Container, Row, Col, Button } from 'react-bootstrap';
import ServicePrices from './Data_Prices';

export const PackageCard = ({ pkg, prices, handleNext, field, isActive }) => {

    const priceIsNumber = typeof pkg.price === "number";
    pkg.price = prices?.[pkg.type] || pkg.price

    return (
        <div className="card mb-4 rounded-5 border-0 text-center">
            <div className={`card-header card-header-min-height ${pkg.type === "full" ? pkg.gradient : 'bg-transparent'} border-0 rounded-bottom rounded-5 py-3 d-flex align-items-center justify-content-center`}>
                <h4 className={`my-0 fw-bold fst-italic ${pkg.textColor}`}>{pkg.name}</h4>
            </div>
            <div className="card-body card-body-min-height">
                <h1 className="fs-2 fw-bold fst-italic">{"£" + pkg.price}</h1>
                <ul className="list-unstyled mt-3 mb-4">
                    {pkg.features.map(feature => (
                        <li key={feature}>{feature}</li>
                    ))}
                </ul>
            </div>
            {!priceIsNumber && (<QuizButton
                packageSelection={pkg}
                currentField={field}
                handleNext={handleNext}
                isActive={isActive}
                gradient={pkg.gradient}
            />)}
        </div>
    )
};

const QuizButton = ({ packageSelection, currentField, handleNext, isActive, gradient }) => {
    return (
        <Button
            size="lg"
            className={`${gradient} m-2 fw-bold fst-italic text-center rounded-pill ${isActive ? "active" : ""}`}
            onClick={() => handleNext("packageSelection", packageSelection)}
        >
            Continue
        </Button>
    )
};



/**
 * Responsible for rendering packages page
 * @param {Function} handleNext  - function to present next page
 * @param {String} field - name of the section to direct
 * @param {Object} packages - list of packages
 * @param {useState} formData - user input
 */
function View_Packages({ handleNext, field, packages, formData }) {
    let { propertyType, bedroomCount, propertyFeature } = formData;

    propertyType = propertyType === "bangalow" ? "bangalow" : "house"
    propertyFeature = propertyFeature === "extension" ? "conservatory" : propertyFeature

    const prices = ServicePrices[propertyType][bedroomCount][propertyFeature];
    const packageTypes = packages[0]

    return (
        <Col as="section" xs={12} className="bg-niklas-gradient rounded-5 p-2 p-md-5">
            <h2 className="fs-1 fw-bold text-white text-center py-3 py-sm-5">Here's your quote</h2>
            <Container>
                <Row>
                    {Object.entries(packageTypes).map(([key, value]) => (
                        <Col className="col-12 col-md-4 text-center" key={packageTypes[key].type}>
                            <PackageCard
                                pkg={packageTypes[key]}
                                prices={prices}
                                handleNext={handleNext}
                                field={field}
                                isActive={formData[field] === packageTypes[key].name}
                            />
                        </Col>
                    ))}
                </Row>
            </Container>
        </Col>
    );
}

export default View_Packages;