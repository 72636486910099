import React from "react";
import Slider from "react-slick";
import { Container, Row, Col } from "react-bootstrap";

const SlickSlider = ({ title, background, settings, reviews }) => {
    return (
        <div className="hero" style={{ backgroundImage: `url(${background})` }} >
            <Container>
                <Row className="py-5 d-flex align-items-center content">
                    <Col className="my-5 py-5 px-5">
                        <h2 className="display-4 text-white text-center mb-5">{title}</h2>
                        <Slider {...settings}>
                            {reviews.map((review, index) => (
                                <div key={index} className="d-flex flex-column align-items-center px-2">
                                    <div className="text-dark bg-light p-3 rounded-3">
                                        <p className="fst-italic card-text">"{review.review}"</p>
                                    </div>
                                    <p className="text-light text-center card-title mt-3">{review.name}, {review.origin}</p>
                                </div>
                            ))}
                        </Slider>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default SlickSlider;