// import { Container, Row, Col } from 'react-bootstrap';

import Header from '../components/Header'
import Footer from '../components/Footer'

import Hero from '../components/Section_Hero';
import Statement from '../components/Section_Statement'
import SectionParagraph from '../components/Section_TitleParagraphBtn'
import MasonryLightbox from '../components/Section_MasonryLightbox'
import SlickSlider from '../components/Section_SlickSlider'
import FAQSection from '../components/Section_FAQ'

import { HOME, GALLERY } from '../content/en'


const Gallery = () => {
    return (
        <>
            <Header />

            <Hero
                backgroundImage={GALLERY.hero.background}
                title={GALLERY.hero.title}
                subtitle={GALLERY.hero.subtitle}
            />

            <Statement content={GALLERY.statement} />

            <SectionParagraph
                title={GALLERY.about.title}
                paragraph={GALLERY.about.paragraph}
            />

            <MasonryLightbox />

            <SlickSlider
                title={HOME.slider.title}
                background={HOME.slider.background}
                settings={HOME.slider.settings}
                reviews={HOME.slider.reviews}
            />
            <div className='divider'></div>
            <FAQSection title={HOME.faq.title} cols={HOME.faq.cols} faqs={HOME.faq.data} />
            <Footer />
        </>
    );
}

export default Gallery;
