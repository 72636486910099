import React, { useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { Container, Row } from 'react-bootstrap';

import { VideoSEO } from './SEO';

const VideoComponent = ({ src, seo, link = false, className = false }) => {
    const videoRef = useRef(null);
    const { ref, inView } = useInView({
        threshold: 0.8,
    });

    useEffect(() => {
        if (inView) {
            const playPromise = videoRef.current.play();
            if (playPromise !== undefined) {
                playPromise.catch(error => {
                    console.log(error);
                });
            }
        } else {
            if (videoRef.current) videoRef.current.pause();
        }
    }, [inView]);

    const videoElement = (
        <>
            <VideoSEO
                title={seo.title}
                desc={seo.desc}
                videoThumb={seo.thumb}
                videoPath={src}
            />
            <video
                style={{ width: "100%", height: "auto" }}
                className='rounded-5'
                muted
                loop
                ref={videoRef}
            >
                <source src={src} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </>
    );

    return (
        <div className={className ? className : ""}>
            <Container ref={ref} style={{ maxWidth: "680px" }}>
                <Row className="py-5 d-flex align-items-center">
                    {link ? (
                        <a href={link}>{videoElement}</a>
                    ) : (
                        videoElement
                    )}
                </Row>
            </Container>
        </div>
    );
};

export default VideoComponent;