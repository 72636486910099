import Header from '../components/Header'
import Footer from '../components/Footer'

import Hero from '../components/Section_Hero';
import Statement from '../components/Section_Statement'
import Section2Col from '../components/Section_2Col'
import TitleParagraphBtn from '../components/Section_TitleParagraphBtn'
import CheckList from '../components/Section_CheckList'
import Features from '../components/Section_Features'
import SlickSlider from '../components/Section_SlickSlider'
import FAQSection from '../components/Section_FAQ'
import VideoSection from '../components/Section_Video'

import ROUTE_CONFIG from '../routes/routeConfig'
import { HOME, SERVICE_GT } from '../content/en'

const AboutSection = () => {
    return <TitleParagraphBtn
        title={SERVICE_GT.about.title}
        paragraph={SERVICE_GT.about.paragraph}
        buttons={SERVICE_GT.about.buttons}
    />
}


function GutterCleaning() {
    return (
        <>
            <Header />

            <Hero
                backgroundImage={SERVICE_GT.hero.background}
                title={SERVICE_GT.hero.title}
            />

            <Statement content={SERVICE_GT.statement} />

            <VideoSection
                src="/videos/window-cleaning.mp4"
                seo={{
                    "title": "Window Cleaning Reel",
                    "desc": "Our team working on window and gutter cleaning all at once.",
                    "thumb": "/videos/window-clean-video-thumb.png"
                }}
                link={ROUTE_CONFIG.QUOTE}
                className="bg-niklas-gradient"
            />

            <Section2Col
                colOne={AboutSection()}
                colTwo={<CheckList
                    icon={SERVICE_GT.checklist.icon}
                    items={SERVICE_GT.checklist.data}
                />}
            />
            <Features title={HOME.features.title} cards={HOME.features.cards} /> */}
            <SlickSlider
                title={HOME.slider.title}
                background={HOME.slider.background}
                settings={HOME.slider.settings}
                reviews={HOME.slider.reviews}
            />
            <div className='divider'></div>
            <FAQSection title={HOME.faq.title} cols={HOME.faq.cols} faqs={HOME.faq.data} />
            <Footer />
        </>
    );
}

export default GutterCleaning;
