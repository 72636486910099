import Header from '../components/Header'
import Footer from '../components/Footer'

import Hero from '../components/Section_Hero';
import Section2Col from '../components/Section_2Col'
import TitleParagraphBtn from '../components/Section_TitleParagraphBtn'
import SlickSlider from '../components/Section_SlickSlider'
import FAQSection from '../components/Section_FAQ'

import { HOME, AREA_OST } from '../content/en'

const AreaOswestry = () => {
    return (
        <>
            <Header />

            <Hero
                backgroundImage={AREA_OST.hero.background}
                title={AREA_OST.hero.title}
                subtitle={AREA_OST.hero.subtitle}
            />

            <Section2Col
                colOne={
                    <TitleParagraphBtn
                        title={AREA_OST.about.title}
                        paragraph={AREA_OST.about.paragraph}
                        buttons={AREA_OST.about.buttons}
                    />
                }
                colTwo={<img src={AREA_OST.about.map} className='w-100 rounded-2' alt='Oswestry Location Map' />}
            />
            <SlickSlider
                title={HOME.slider.title}
                background={HOME.slider.background}
                settings={HOME.slider.settings}
                reviews={HOME.slider.reviews}
            />
            <div className='divider'></div>
            <FAQSection title={HOME.faq.title} cols={HOME.faq.cols} faqs={HOME.faq.data} />
            <Footer />
        </>
    );
}

export default AreaOswestry;
