import React from 'react';
import { Analytics } from '@vercel/analytics/react';
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import SEO from "./components/SEO";
import ROUTES from "./routes/routes";
import REDIRECTS from "./routes/redirectes";

import * as en from './content/en'

// Your component that should redirect
function Redirector({ to }) {
  const navigate = useNavigate();

  React.useEffect(() => {
    navigate(to);
  }, [navigate, to]);

  return null; // render nothing
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {Object.keys(ROUTES).map(key => {
          return (
            <Route
              key={key}
              path={ROUTES[key].path}
              exact={ROUTES[key].exact || false}
              element={
                <SEO
                  title={en[key]?.hero?.title + " | " + en.GLOBAL.schema.name}
                  description={en[key]?.statement || en[key]?.hero?.subtitle}
                  path={en.GLOBAL.schema.url + ROUTES[key]?.path}
                >
                  {ROUTES[key].element}
                  <Analytics />
                </SEO>
              }
            />
          )
        })}
        {REDIRECTS.map(
          (redirect, idx) =>
            <Route key={idx} path={redirect.from} element={<Redirector to={redirect.to} />} />
        )}
      </Routes>
    </BrowserRouter>
  );
}

export default App;