import Header from '../components/Header'
import Footer from '../components/Footer'

import Hero from '../components/Section_Hero';
import Statement from '../components/Section_Statement'
import Section2Col from '../components/Section_2Col'
import TitleParagraphBtn from '../components/Section_TitleParagraphBtn'
import CheckList from '../components/Section_CheckList'
import Features from '../components/Section_Features'
import SlickSlider from '../components/Section_SlickSlider'
import FAQSection from '../components/Section_FAQ'

import { HOME, SERVICE_CM } from '../content/en'

function CommercialCleaning() {
    return (
        <>
            <Header />

            <Hero
                backgroundImage={SERVICE_CM.hero.background}
                title={SERVICE_CM.hero.title}
                button={SERVICE_CM.hero.buttons}
            />

            <Statement content={SERVICE_CM.statement} />

            <Section2Col
                colOne={<TitleParagraphBtn
                    title={SERVICE_CM.about.title}
                    paragraph={SERVICE_CM.about.paragraph}
                    buttons={SERVICE_CM.about.buttons}
                />}
                colTwo={<CheckList
                    icon={SERVICE_CM.checklist.icon}
                    items={SERVICE_CM.checklist.data}
                />}
            />
            <Features title={HOME.features.title} cards={HOME.features.cards} /> */}
            <SlickSlider
                title={HOME.slider.title}
                background={HOME.slider.background}
                settings={HOME.slider.settings}
                reviews={HOME.slider.reviews}
            />
            <div className='divider'></div>
            <FAQSection title={HOME.faq.title} cols={HOME.faq.cols} faqs={HOME.faq.data} />
            <Footer />
        </>
    );
}

export default CommercialCleaning;
