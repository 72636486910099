import Header from '../components/Header'
import Footer from '../components/Footer'

import Hero from '../components/Section_Hero';
import Statement from '../components/Section_Statement'
import Section2Col from '../components/Section_2Col'
import TitleParagraphBtn from '../components/Section_TitleParagraphBtn'
import CheckList from '../components/Section_CheckList'
import SlickSlider from '../components/Section_SlickSlider'
import FAQSection from '../components/Section_FAQ'

import GoCardlessSnippet from '../components/GoCardless'
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';

import { HOME, PAYMENT } from '../content/en'

function Payment() {
    return (
        <>
            <Header />

            <Hero
                backgroundImage={PAYMENT.hero.background}
                title={PAYMENT.hero.title}
                subtitle={PAYMENT.hero.subtitle}
                buttons={PAYMENT.hero.buttons}
            />

            <Statement content={PAYMENT.statement} />

            <Section2Col
                colOne={
                    <>
                        <TitleParagraphBtn
                            title={PAYMENT.about.title}
                            paragraph={PAYMENT.about.paragraph}
                            buttons={PAYMENT.about.buttons}
                        />
                        <CheckList items={PAYMENT.about.payment_options} icon={faAngleDoubleRight} />
                    </>
                }
                colTwo={
                    <>
                        <TitleParagraphBtn
                            paragraph={PAYMENT.about.paragraph_two}
                        />
                        <GoCardlessSnippet />
                    </>
                }
            />

            <SlickSlider
                title={HOME.slider.title}
                background={HOME.slider.background}
                settings={HOME.slider.settings}
                reviews={HOME.slider.reviews}
            />
            <div className='divider'></div>
            <FAQSection title={HOME.faq.title} cols={HOME.faq.cols} faqs={HOME.faq.data} />
            <Footer />
        </>
    );
}

export default Payment;
