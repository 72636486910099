import { Col, ListGroup } from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CheckList = ({ items, icon }) => {

    const getIcon = (index) => {
        if (Array.isArray(icon)) {
            if (index < icon.length) {
                return icon[index];
            }
            return icon[icon.length - 1];
        }
        return icon;
    }

    return (
        <Col className="m-2">
            <ListGroup variant="flush" className="bg-transparent">
                {items && items.map((item, index) => (
                    <ListGroup.Item key={index} bsPrefix="mb-3" className="d-flex justify-content-between align-items-center">
                        <FontAwesomeIcon icon={getIcon(index)} className="fs-4 text-niklas" />
                        <div className="ms-2 me-auto">{item}</div>
                    </ListGroup.Item>
                ))}
            </ListGroup>
        </Col>
    )
}

export default CheckList;