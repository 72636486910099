import Header from '../components/Header'
import Footer from '../components/Footer'

import Hero from '../components/Section_Hero';
import QuoteQuiz from '../components/Section_QuoteForm'
import SlickSlider from '../components/Section_SlickSlider'
import FAQSection from '../components/Section_FAQ'

import { HOME, QUOTE } from '../content/en'


const GetYourQuote = () => {
    return (
        <>
            <Header />

            <Hero
                backgroundImage={QUOTE.hero.background}
                title={QUOTE.hero.title}
                subtitle={QUOTE.hero.subtitle}
            />

            <QuoteQuiz />

            <SlickSlider
                title={HOME.slider.title}
                background={HOME.slider.background}
                settings={HOME.slider.settings}
                reviews={HOME.slider.reviews}
            />
            <div className='divider'></div>
            <FAQSection title={HOME.faq.title} cols={HOME.faq.cols} faqs={HOME.faq.data} />
            <Footer />
        </>
    );
}

export default GetYourQuote;
