import ROUTE_CONFIG from './routeConfig';

const REDIRECTS = [
    { from: '/commercial', to: ROUTE_CONFIG.SERVICE_CM },
    { from: '/contact', to: ROUTE_CONFIG.CONTACT_US },
    { from: '/about-us', to: ROUTE_CONFIG.HOME },
    { from: '/domestic', to: ROUTE_CONFIG.SERVICE_WC },
];


export default REDIRECTS